import React from 'react'

const footer = () => {
  return (
    <>
      <div className='box '></div>
      <div className='box2'></div>
    </>
  )
}

export default footer
