import React from 'react';
import MediaImage from '../images/SocialTwo.PNG';
import PackageImage from '../images/basic.jpg';
import Intermediate from '../images/PhoneSpotify.jpg';
import Desk from '../images/Desk.jpg';
import Likes from '../images/Likes.jpg';
import CheckoneSP from '../images/CheckoneSP.mp4';
import ReactPlayer from 'react-player'
import IGTKYT from '../images/IGTKYT.PNG';
import IG from '../images/IG.png';
import Spotify from '../images/Spotify.png';
import Youtube from '../images/Youtube.png';
import Tiktok from '../images/Tiktok.png';
import Beatport from '../images/Beatport.png';
import Soundcloud from '../images/Soundcloud.png';
import Linktree from '../images/Linktree.png';
import Slides from '../images/Slides.mp4';
import Spinner from '../images/Spinner.mp4';
import Comps from '../images/Comps.mp4';
import InstaPhone from '../images/InstaPhone.jpg';

const Media = () => {
  return (
    <div>
      <section className="hero-section text-light pb-3">
        <div className="container">
          <div className="row align-items-center">

            <div className="col-md-6 media-text text-center pb-5">
              <h3>Disrupt Media</h3>
              <h4>Manage your online presence with us.</h4>
              
              
            </div>
          </div>
        </div>
      </section>

      <section className="hero-section text-light second-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={MediaImage} className="img-fluid p-4 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Social Media Icons" />
            </div>
            <div className="col-md-6 media-text">
              <h3 className='basic'>The Basics Package</h3>
              <h4>Ensures your followers are up to date with your music on all the platforms that matter.</h4>
              <div className=" mt-5">
                <a href="https://buy.stripe.com/28og2eewE2Iw22s9AB"><button className="btn btn-light signup ">Sign up</button></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="package-section text-light py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="package-text p-4" style={{marginLeft:'16%', marginTop:'-10%'}}>

                <hr />
                <h4>THE PACKAGE</h4>
                <p>The basics package is aimed at artists who are time poor but want to make sure their online presence is always up to date and looking great.  We cover all the main online areas where you will want to engage with your fans and will use our expertise to ensure the content we make is maximally visible under the platform’s algorithm.</p>

                <hr />
                
              </div>
            </div>
            <div className="col-md-6">
              <img src={PackageImage} className=" img-fluid package-img" alt="DJ at work" />
            </div>
          </div>
        </div>
      </section>

      <section className="package-section  text-light py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6  order-md-1 order-2" >
              <img src={Intermediate} className="img-fluid p-3 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Club scene" />
            </div>
            <div className="col-md-6 order-md-2 order-1">
              <div className="package-text p-4" style={{marginLeft:'-1%', marginTop:'-10%'}}>
                <hr />
                <h4>THE PROCESS</h4> 
                <p>Each month we will discuss with you in advance our strategy for that month.  This could be something like: posting a spinner video of your new song, along with some Tiktoks on how it is made, a new Beatport Chart for your latest release, a Spotify Playlist of that chart and some pictures of you in the studio.</p>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="package-section text-light py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="package-text p-4" style={{marginLeft:'16%', marginTop:'-10%'}}>

                <hr />
                <h4>THE PRICE</h4>
                <p>At just £59.99 pcm the Basics package is designed to be a no-brainer in terms of value.   Your subscription pays for a certain number of tasks each month which we present to you in advance.  If you want different things done no problem-- we simply switch the tasks up.</p>
                <hr />
                
              </div>
            </div>
            <div className="col-md-6">
              <img src={Desk} className=" img-fluid package-img" alt="DJ at work" />
            </div>
          </div>
        </div>
      </section>



      <section className="hero-section text-light pb-3" style={{ backgroundColor: '#2d2d2d'}}>
        <div className="container">
          <div className="row align-items-center">

            <div className="col-md-6 media-text text-center">
              <h3 className='work-text'>How It Works</h3>
              <h4>Lets look in detail at what we do.</h4>
            </div>
          </div>
        </div>
      </section>

      <section className="package-section text-light second-section py-5" style={{ backgroundColor: '#1c1c1e'}}>
        <div className="container pt-3">
          <div className="row align-items-center ">
            <div className="col-md-6">
              <img src={IGTKYT} className="img-fluid p-4 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Social Media Icons" />
            </div>
            <div className="col-md-6 media-text">
              <h3 style={{ fontSize: '3rem' }}>Content Creation</h3>
              <hr />
              <h4>VIDEO</h4>
              <p>For platforms like Instagram and Tiktok, video is of course the way people here your new music.  We make a selection of videos.</p>
              <hr />   
            </div>
          </div>
        </div>
      </section>





      <section className="package-section text-light  second-section py-5">
        <div className="container">
          <div className="row align-items-center mb-4">
            <div className="col-md-6  media-text mobile-center">
              <div classname="package-text p-4" style={{ marginTop:'-10%'}}>
                <h4>Slides Videos</h4>
                <p>We give each song an identity and create a short video upon that aesthetic. For example, the aesthetic for this Afro House track is a nod to the desert expanse of Burning Man.</p>
              </div>
            </div>
            <div className="col-md-6 media-text">
              <div style={{ maxWidth: '100%', padding:'10px' }}>
                <ReactPlayer 
                  url={Slides} 
                  width="100%"
                  height="auto"
                  controls={true}
                  classname='video'
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center mb-4">
            <div className="col-md-6  media-text mobile-center">
              <div classname="package-text p-4" style={{ marginTop:'-10%'}}>
                <h4>Spinner Videos</h4>
                <p>The easiest and most popular way to show music online. The image for the spinner could be based on the track's aesthetic or something simple like a picture of the artist.</p>
              </div>
           </div>
            <div className="col-md-6  media-text">
              <div style={{ maxWidth: '100%', padding:'10px' }}>
                <ReactPlayer 
                  url={Spinner} 
                  width="100%"
                  height="auto"
                  controls={true}
                />
              </div>
            </div>
          </div>

          <div className="row align-items-center mb-4">
            <div className="col-md-6  media-text mobile-center">
              <div classname="package-text p-4 desktop-margin" style={{marginTop:'-10%'}}>
                <h4>Production Videos</h4>
                <p>Components of your song can be isolated to appeal to show how the track is made are very popular on Tiktok. These videos appeal to the large music production orientated audience. </p>
              </div>
            </div>
            <div className="col-md-6  media-text">
              <div style={{ maxWidth: '100%', padding:'10px' }}>
                <ReactPlayer 
                  url={Comps} 
                  width="100%"
                  height="auto"
                  controls={true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

            <section className="package-section text-light second-section py-5" style={{ backgroundColor: '#1c1c1e'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={InstaPhone} className="img-fluid p-4 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Social Media Icons" />
            </div>
            <div className="col-md-6 media-text">
              <h3 style={{ fontSize: '3rem' }}>Posting Content</h3>
              <hr />
          
              <p>For a complete and professional online presence we cover all major platforms. We post regularly to ensure the algorithms reward you with maximal exposure.</p>
              <hr />   
            </div>
          </div>
        </div>
      </section>   


      <section className="package-section text-light second-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 d-flex justify-content-center justify-content-md-start">
              <img src={IG} className="img-fluid p-4 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }}  alt="Social Media Icons" />
            </div>
            <div className="col-md-6 media-text mobile-center">
              {/* <h3 style={{ fontSize: '3rem' }}>Instagram</h3> */}
                
                <p className="px-5">Instagram is very important and requires regular posting. Typical content includes:</p>
                <ul className="list-left-align pt-5" style={{ maxWidth: '90%', marginLeft:'50px' }}>
                  <li>Posting spinner videos</li>
                  <li>Posting slides videos</li>
                  <li>Posting production videos</li>
                  <li>Posting new images</li>
                  <li>Posting charts and playlists</li>
                  <li>Likes, comments, and messages activity</li>
                </ul>
            </div>
          </div>
        </div>
      </section> 

      <section className="package-section text-light second-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 d-flex justify-content-center justify-content-md-start">
              <img src={Tiktok} className="img-fluid p-4 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Social Media Icons" />
            </div>
            <div className="col-md-6 media-text mobile-center">
              {/* <h3 style={{ fontSize: '3rem' }}>TikTok</h3> */}
                
                <p className="px-5">Tiktok is hugely popular with younger audiences and is now essential for aspirng musicians. Typical content includes:</p>
                <ul className="list-left-align pt-5 " style={{ marginLeft:'30px' }}>
                  <li>Posting shorter song clips</li>
                  <li>Posting spinner videos</li>
                  <li>Posting slides videos</li>
                  <li>Posting production videos</li>
                  <li>Likes, comments, and messages activity</li>
                </ul>
            </div>
          </div>
        </div>
      </section> 

      <section className="package-section text-light second-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 d-flex justify-content-center justify-content-md-start">
              <img src={Soundcloud} className="img-fluid p-4 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Social Media Icons" />
            </div>
            <div className="col-md-6 media-text mobile-center">
              {/* <h3 style={{ fontSize: '3rem' }}>Soundcloud</h3> */}
                
                <p className="px-5">Soundcloud is the platform to put new clips and mixes. Typical actions include:</p>
                <ul className="list-left-align pt-5" style={{ maxWidth: '90%', marginLeft:'50px' }}>
                  <li>Posting song clips and mixes with chosen artwork</li>
                  <li>Reposting content</li>
                  <li>Organising into playlists</li>
                  <li>Likes, comments, and messages activity</li>
                </ul>
            </div>
          </div>
        </div>
      </section> 

      <section className="package-section text-light second-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 d-flex justify-content-center justify-content-md-start">
              <img src={Spotify} className="img-fluid p-4 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Social Media Icons" />
            </div>
            <div className="col-md-6 media-text mobile-center">
              {/* <h3 style={{ fontSize: '3rem' }}>Spotify</h3> */}
                
                <p className="px-5">Spotify is the most popular streaming platform and that is becoming increasingly relied upon to assess an artist's popularity. Typical actions include:  </p>
                <ul className="list-left-align pt-5" style={{ maxWidth: '90%', marginLeft:'50px' }}>
                  <li>Pitching music to get on playlists</li>
                  <li>Posting playlists your fanbase can engage with</li>
                  <li>Maintaining your artist account </li>
  
                </ul>
            </div>
          </div>
        </div>
      </section> 

      <section className="package-section text-light second-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 d-flex justify-content-center justify-content-md-start">
              <img src={Youtube} className="img-fluid p-4 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Social Media Icons" />
            </div>
            <div className="col-md-6 media-text mobile-center">
              {/* <h3 style={{ fontSize: '3rem' }}>YouTube</h3> */}
                
                <p className="px-5">Youtube is still very popular for listening to music . Typical actions include:  </p>
                <ul className="list-left-align pt-5" style={{ maxWidth: '90%', marginLeft:'50px' }}>
                  <li>Posting videos of released music</li>
                  <li>Posting reels of new music</li>
                  <li>Likes, comments, and messages activity</li>
  
                </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="package-section text-light second-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 d-flex justify-content-center justify-content-md-start">
              <img src={Linktree} className="img-fluid p-4 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Social Media Icons" />
            </div>
            <div className="col-md-6 media-text mobile-center">
              {/* <h3 style={{ fontSize: '3rem' }}>Linktree</h3> */}
                
                <p className="px-5">Linktree is used so your fanbase can find everything about you, from your latest releases to your other accounts. Typical actions include:  </p>
                <ul className="list-left-align pt-5" style={{ maxWidth: '90%', marginLeft:'50px' }}>
                  <li>Adding new links and removing others</li>

  
                </ul>
            </div>
          </div>
        </div>
      </section>




    </div>
  );
};

export default Media;
