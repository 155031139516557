import React from 'react';
import neviks from '../images/niviks.jpg';
import seamus from '../images/seamus.jpg';
import ollie from '../images/ollie.jpg';
import rueben from '../images/rueben.jpg';
import fordal from '../images/fordal.jpg';
import meley from '../images/meley.jpg';

const artists = [
  { name: 'neviks', id: 1, image: neviks, instagramLink: 'https://www.instagram.com/neviks_music/'},
  { name: 'Seamus Shevlin', id: 2, image: seamus,instagramLink: 'https://www.instagram.com/seamus_shevlin/'},
  { name: 'Ollie Bentley', id: 3, image: ollie,  instagramLink:'https://www.instagram.com/ollie_bentley_/'  },
  { name: 'Reuben Anderson', id: 4, image: rueben, instagramLink:'https://www.instagram.com/reubensworld/' },
  { name: 'FORDAL', id: 5, image: fordal, instagramLink:'https://www.instagram.com/fordaldj/' },
  { name: 'Maley', id: 6, image: meley, instagramLink:'https://www.instagram.com/ollie_bentley_/' },
  
];

const Roster = () => {
  return (
    <div className="container-fluid text-center text-light roster ">
    <h2 className="pt-5">ROSTER</h2>
    <p className="pt-2 roster-para">Please contact charlotte@disrupt-artists.com</p>
    <div className="row w-75 mx-auto ">
        {artists.map((artist, index) => (
            <div className="col-lg-4 col-md-6 d-flex justify-content-center p-2 my-2" key={artist.id}>
                <a href={artist.instagramLink} target="_blank" className="text-decoration-none">
                    <div className="card mt-2">
                        <img className="card-img-top" src={artist.image} alt={artist.name} />
                        <div className="card-body text-light py-4">
                            <p className="card-text py-3">{artist.name}</p>
                        </div>
                    </div>
                </a>
            </div>
        ))}
    </div>
</div>

  );
};

export default Roster;
