import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './navbar.css'; 
import logo from '../images/logo.png'; 

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getLinkClass = (path) => location.pathname === path ? 'active' : '';

  return (
    <nav className={`navbar ${isOpen ? 'open' : ''}`}>
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="navbar-toggle" onClick={handleToggle}>
        {isOpen ? <span className="close-icon">&times;</span> :  <span className="hamburger-icon">&#9776;</span>}
      </div>
      <div className={`navbar-items ${isOpen ? 'open' : ''}`}>
        <Link to="/" className={getLinkClass('/')} onClick={handleToggle}>Home</Link>
        <Link to="/media" className={getLinkClass('/media')} onClick={handleToggle}>Media</Link>
        <Link to="/roster" className={getLinkClass('/roster')} onClick={handleToggle}>Roster</Link>
      </div>
    </nav>
  );
};

export default Navbar;
