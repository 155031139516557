import React from 'react';
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Media from './pages/Media';
import Roster from './pages/Roster';
import Footer from './components/footer';


const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/media" element={<Media />} />
        <Route path="/roster" element={<Roster />} />
      </Routes>
      <Footer/>
    </Router>
  );
};

export default App;
